import Image from 'next/image';
import { useState } from 'react';

import { staticMediaStoreBaseURL } from '@/config/common';
import { EventCartOrderBanner, EventCartOrderDetails } from '@/molecules';

const EventCartOrderInfoOrganism = ({
  cartOrderBannerDetail,
  userEventDetails
}) => {
  const [showEventCartOrderDetails, setShowEventCartOrderDetails] =
    useState(true);
  const toggleToShowCartOrderDetailsBanner = () =>
    setShowEventCartOrderDetails(!showEventCartOrderDetails);
  return (
    <div className='mb-6 relative'>
      <EventCartOrderBanner
        {...{ cartOrderBannerDetail, toggleToShowCartOrderDetailsBanner }}
      />
      {showEventCartOrderDetails && (
        <EventCartOrderDetails {...userEventDetails} />
      )}
      <div className='cursor-pointer absolute right-5 -bottom-5'>
        <Image
          alt='down angle'
          className={`transform ${
            showEventCartOrderDetails ? 'rotate-180' : '-rotate-0'
          }`}
          height={32}
          onClick={() => toggleToShowCartOrderDetailsBanner()}
          priority={true}
          src={`${staticMediaStoreBaseURL}/icons/down-angle-circle-icon.svg`}
          width={32}
        />
      </div>
    </div>
  );
};

export default EventCartOrderInfoOrganism;
