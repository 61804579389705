import { NoItemFound, Tabs } from '@/molecules';
import UserEventSummaryCard from '@/molecules/myEvents/UserEventSummaryCard';

const UserEventSummaryCardList = ({ userEventSummaryCardList = [] }) =>
  userEventSummaryCardList?.length > 0 ? (
    userEventSummaryCardList.map((userEventSummaryCard) => (
      <UserEventSummaryCard
        key={userEventSummaryCard.id}
        {...{ userEventSummaryCard }}
      />
    ))
  ) : (
    <NoItemFound />
  );

const UserEventNavigatorOrganism = ({
  ongoingUserEventSummaryCardList,
  pastUserEventSummaryCardList
}) => (
  <div className='px-1 md:px-10 relative'>
    <Tabs
      {...{
        className: 'py-1 w-full',
        tabContainerStyle: 'py-1 px-3  w-full',
        tabs: [
          {
            content: (
              <UserEventSummaryCardList
                {...{
                  userEventSummaryCardList: ongoingUserEventSummaryCardList
                }}
              />
            ),
            id: 1,
            title: 'Ongoing',
            tabsClass: 'float-left text-center mt-3 md:mt-0 w-1/2 md:w-auto'
          },
          {
            content: (
              <UserEventSummaryCardList
                {...{
                  userEventSummaryCardList: pastUserEventSummaryCardList
                }}
              />
            ),
            id: 2,
            title: 'Past',
            tabsClass:
              'float-left text-center mt-3 md:mt-0 md:ml-10  w-1/2 md:w-auto'
          }
        ],
        tabsClass:
          'p-0 md:px-3 md:py-2 text-sm md:text-lg font-medium text-center inline'
      }}
    />
  </div>
);

export default UserEventNavigatorOrganism;
