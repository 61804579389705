import 'react-datepicker/dist/react-datepicker.css';

import { Button, ProductPrice, Text } from '@/atoms';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { PRODUCT_STATUS, PRODUCT_TYPE } from '@/config/common';
import phoneConfig from '@/config/phone';
import { PAGE_NAME } from '@/lib/gtm';
import {
  NRFQProductAddToCart,
  ProductQuantityRangeAlertError
} from '@/molecules';
import SpinnerButton from '@/molecules/common/SpinnerButton';
import { isPlannerLoggedIn } from '@/services/identity.service';
import { handleAddToCart } from '@/services/product.service';

const { phoneNumbers } = phoneConfig;

const SpinnerInput = ({
  changeQuantity,
  handleOnBlur,
  handleValueChange,
  isPublished,
  minBookable,
  product,
  quantity,
  showMaxBookableAlert,
  showQuantityRangeAlert
}) => (
  <div className='flex justify-between gap-4'>
    <div
      className={`flex flex-row w-36 rounded-md text-2xl border ${
        showQuantityRangeAlert ? 'border-error-red' : 'border-gray-200'
      }`}
    >
      <div
        className={`border-r border-gray-200 bg-gray
        h-12 w-full rounded-l-md focus:outline-none flex
        justify-center items-center cursor-pointer
        ${quantity === minBookable && 'cursor-not-allowed'}`}
        name='decrease'
      >
        <SpinnerButton
          {...{
            className: `${
              quantity === minBookable &&
              !isPublished &&
              'opacity-50 cursor-not-allowed'
            } ${quantity === minBookable && 'cursor-not-allowed'} w-full`,
            content: '-',
            disabled: quantity === minBookable || !isPublished,
            onClick: (e) => changeQuantity(e, 'decrease')
          }}
        />
      </div>
      <input
        className='h-12 w-20 text-center focus:outline-none'
        max={product.maxBookable || Infinity}
        onBlur={handleOnBlur}
        onChange={(e) => handleValueChange(e.target.value)}
        text='number'
        type='number'
        value={quantity}
      />
      <div
        className={`border-l border-gray-200 bg-gray
       h-12 w-full rounded-r-md focus:outline-none
       flex justify-center items-center cursor-pointer
        ${showMaxBookableAlert && 'cursor-not-allowed'}`}
        name='increase'
      >
        <SpinnerButton
          {...{
            content: '+',
            disabled: showMaxBookableAlert || !isPublished,
            onClick: (e) => changeQuantity(e, 'increase'),
            style: `${
              showMaxBookableAlert &&
              !isPublished &&
              'opacity-50 cursor-not-allowed'
            }`
          }}
        />
      </div>
    </div>
  </div>
);

const Label = ({ label }) => (
  <Text
    {...{
      className: 'text-sm text-brand mt-2 font-medium hidden md:flex',
      content: label
    }}
  />
);

const RFQProductAddToCart = ({
  createCart,
  isQuantityInvalid,
  product,
  quantity,
  revertQuantityToMin,
  setShowAddedToCartAlert,
  updateCartInPortalHeader
}) => (
  <div className='z-10 w-full'>
    {product.status === PRODUCT_STATUS.PUBLISHED ? (
      <Button
        {...{
          className: `h-11 md:h-auto bg-gradient-to-r from-light-orange via-dark-orange to-rose px-16 md:py-4 w-full text-white text-base focus:outline-none rounded-lg`,
          disabled: isPlannerLoggedIn,
          onClick: () =>
            handleAddToCart({
              createCart,
              isQuantityInvalid,
              pageName: PAGE_NAME.PDP.label,
              product,
              quantity,
              revertQuantityToMin,
              setShowAddedToCartAlert,
              updateCartInPortalHeader
            }),
          children: 'Add to Cart'
        }}
      />
    ) : (
      <Button
        {...{
          children: 'Unavailable',
          className: `text-white bg-gradient-to-r from-light-orange via-dark-orange to-rose p-3 rounded-lg md:px-16 text-sm md:text-base font-medium opacity-50 cursor-not-allowed`,
          disabled: true
        }}
      />
    )}
  </div>
);
const TicketProductAddToCart = ({ setShowBookTicketForm, isMobile }) => (
  <button
    className={`h-11 w-full md:h-auto bg-gradient-to-r from-light-orange via-dark-orange to-rose px-16 md:py-4
    text-white text-base focus:outline-none rounded-lg ${
      isMobile ? '' : '-mt-3'
    }`}
    onClick={() => setShowBookTicketForm(true)}
  >
    Request Now
  </button>
);

const addToCart = {
  Cart: NRFQProductAddToCart,
  Quote: RFQProductAddToCart,
  Ticket: TicketProductAddToCart
};

const ProductPriceBoxOrganism = ({
  decreaseQuantity,
  increaseQuantity,
  isMobile,
  isQuantityInvalid,
  minBookable,
  product,
  quantity,
  revertQuantityToMin,
  setIsQuantityInvalid,
  setQuantity,
  setShowAddedToCartAlert,
  setShowBookTicketForm,
  setShowQuantityRangeAlert,
  showMaxBookableAlert,
  showPricingDetails,
  showQuantityRangeAlert,
  ticketTypeDetails
}) => {
  const { createCart, updateCartInPortalHeader } = useUIContext();

  const maxBookable = product.maxBookable || Infinity;
  const isPublished = product.status === PRODUCT_STATUS.PUBLISHED;
  const handleChangeQuantity = {
    increase: increaseQuantity,
    decrease: decreaseQuantity
  };

  const handleValueChange = (value) => {
    setShowQuantityRangeAlert(false);
    const inputValue = parseFloat(value);
    if (inputValue < 0) {
      return;
    }
    setQuantity(inputValue);
    setIsQuantityInvalid(
      Boolean(
        inputValue > maxBookable ||
          inputValue < minBookable ||
          Number.isNaN(inputValue)
      )
    );
  };

  const handleOnBlur = (e) => {
    e.stopPropagation();
    if (e.target.value < minBookable || e.target.value === '') {
      setShowQuantityRangeAlert(true);
      setQuantity(minBookable);
    }
    if (e.target.value > maxBookable) {
      setShowQuantityRangeAlert(true);
      setQuantity(maxBookable);
    }
    setTimeout(() => {
      setIsQuantityInvalid(false);
    }, 1000);
    setShowQuantityRangeAlert(false);
  };

  const changeQuantity = (e, type) => {
    handleChangeQuantity[type]({
      quantity,
      maxBookable,
      minBookable,
      setShowQuantityRangeAlert,
      setQuantity
    });
  };

  const AddToCart = addToCart[product.type];
  const showSpinnerInput =
    product.type === PRODUCT_TYPE.CART && isPublished && maxBookable > 1;
  return (
    <>
      <div
        className={`product-price-box w-full md:w-1/3 border md:rounded-lg flex flex-col self-start pb-3 px-6 md:p-6 fixed md:sticky md:top-24 bottom-0 bg-white`}
      >
        <div className='hidden md:block'>
          <ProductPrice
            {...{ product, showPricingDetails, ticketTypeDetails }}
          />
        </div>
        {maxBookable > 0 && <Label {...{ label: 'Excluding VAT' }} />}
        <div className='flex gap-4 mt-3'>
          {showSpinnerInput && (
            <SpinnerInput
              {...{
                changeQuantity,
                handleOnBlur,
                handleValueChange,
                isPublished,
                minBookable,
                product,
                quantity,
                showMaxBookableAlert,
                showQuantityRangeAlert
              }}
            />
          )}
          <AddToCart
            {...{
              createCart,
              isMobile,
              isPlannerLoggedIn,
              isQuantityInvalid,
              minBookable,
              product,
              quantity,
              revertQuantityToMin,
              setShowAddedToCartAlert,
              setShowBookTicketForm,
              showQuantityRangeAlert,
              updateCartInPortalHeader
            }}
          />
        </div>
        {showQuantityRangeAlert && (
          <ProductQuantityRangeAlertError
            {...{
              className: 'hidden md:block',
              maxBookable,
              minBookable,
              phoneNumbers
            }}
          />
        )}
      </div>
    </>
  );
};

export default ProductPriceBoxOrganism;
